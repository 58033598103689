<template>
  <div class="card text-left">
    <div class="card-header">
      <div class="row align-items-center">
        <div class="col-8">
          <h3 class="mb-0">Settings</h3>
        </div>
      </div>
    </div>

    <div class="card-body">
      <form @submit.prevent="submitHandler()" novalidate>
        <div class="pl-lg-4">
          <div class="row">
            <div class="col-lg-6" v-for="(field, i) in schema.col6" :key="i">
              <div class="form-group">
                <label class="form-control-label" :for="field.label">{{
                  field.label
                }}</label>

                <template>
                  <input
                    type="text"
                    :id="field.label"
                    class="form-control"
                    :placeholder="field.placeholder"
                    v-model="form[field.model]"
                  />
                  <div
                    v-if="submitted && getError(field.model)"
                    class="text-danger font-12"
                  >
                    {{ field.label }} is required.
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>

        <div class="pl-lg-4" v-for="(field, j) in schema.col12" :key="j">
          <div class="form-group">
            <template v-if="field.type === 'ckeditor'">
              <label class="form-control-label">{{ field.label }}</label>
              <editor
                class="form-control"
                :id="`ckeditor-${j}`"
                v-model="form[field.model]"
                :api-key="ckeditor.API_KEY"
                :init="ckeditor.config"
              >
              </editor>
              <div
                v-if="submitted && getError(field.model)"
                class="text-danger font-12"
              >
                {{ field.label }} is required.
              </div>
            </template>

            <template v-else>
              <input
                type="text"
                :id="field.label"
                class="form-control"
                :placeholder="field.placeholder"
                v-model="form[field.model]"
              />
              <div
                v-if="submitted && getError(field.model)"
                class="text-danger font-12"
              >
                {{ field.label }} is required.
              </div>
            </template>
          </div>
        </div>

        <div class="pl-lg-4">
          <button class="btn btn-primary" type="submit">Update</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Schema from "@/store/in-memory-db/app/settings.json";
import Editor from "@tinymce/tinymce-vue";
import CkConfig from "@/json/ckEditor.js";
import { required } from "vuelidate/lib/validators";

export default {
  name: "AdminPagesEdit",
  components: {
    editor: Editor,
  },
  data() {
    return {
      editors: {},
      submitted: false,
      schema: Schema,
      form: {},
      ckeditor: CkConfig(),
    };
  },

  validations() {
    return {
      form: this.fieldsValidation,
    };
  },

  computed: {
    ...mapGetters("user", ["settings"]),

    fieldsValidation() {
      const items = {};
      for (const col12 of this.schema.col12) {
        items[col12.model] = { required };
      }
      for (const col6 of this.schema.col6) {
        items[col6.model] = { required };
      }
      return items;
    },
  },
  mounted() {
    this.$store.dispatch("user/settings").then((res) => {
      console.log(res);
    });
  },
  watch: {
    settings: function(val) {
      this.form = Object.assign({}, val);
    },
  },
  methods: {
    getError(field) {
      if (this.$v.form[field]) {
        return this.$v.form[field].$invalid;
      }
    },

    submitHandler() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.$store.dispatch("user/update_settings", this.form);
    },
  },
};
</script>

<style>
.font-12 {
  font-size: 12px;
}
</style>
